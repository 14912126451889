export class AddressStatus {
    static FALLO = -1;
    static PROCESADA = 1;
    static ASIGNADA = 2;
    static GUIA = 3;
    static EN_TRANSITO_A_CIUDAD_DESTINO = 4;
    static ASIGNADA_MENSAJERO = 5;
    static INICIADO = 6;
    static ENTREGADO = 7;
    static NO_ENTREGADO = 8;
    static DEVUELTO = 9;
    static DEVUELTO_REMITENTE = 10;
    static ALISTAMIENTO = 11;
    static TELEMERCADEO = 12;
    static INCAUTADO = 13;
    static RECLAMAR_EN_SUCURSAL = 14;
    static PRODUCTO_PERDIDO = 15;
    static PRODUCTO_DANIADO = 16;
    static CENTRO_ACOPIO = 17;
    static PACKING = 18;
    static ANULADO = 19;
    static DESTRUCCION = 20;
    static EN_INVESTIGACION = 21;
}

export const CompanyTypes = [
    {
        value: '0',
        label: "Seleccionar"
    },
    {
        value: 'DSP',
        label: 'DSP'
    },
    {
        value: 'OPERATOR-INV',
        label: 'Operador de Inventario'
    },
    {
        value: 'OPERATOR-LOG',
        label: 'Operador Logístico'
    },
    {
        value: 'SHIPPER',
        label: 'Remitente'
    }
]

export const CompanyTypesEnum = {
    SHIPPER: 'SHIPPER',
    LOGISTICS_OPERATOR: 'OPERATOR-LOG',
    INVENTORY_OPERATOR: 'OPERATOR-INV',
    DSP: 'DSP',
}

export const MovementTypesEnum = {
    INCOMING: 'INCOMING',
    OUTGOING: 'OUTGOING',
}

export const MovementSubTypesEnum = {
    ORDERED: 'ORDERED',
    RECEIVING: 'RECEIVING',
    RETURN: 'RETURN',
    DAMAGED: 'DAMAGED',
    DESTROYED: 'DESTROYED',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    SEIZED: 'SEIZED',
    ADJUSTMENT: 'ADJUSTMENT',
    RELOCATION: 'RELOCATION',
}


export const SpanishMovementTypesEnum = {
    INCOMING: 'INGRESO',
    OUTGOING: 'EGRESO',
    RESERVED: 'RESERVADO',
}

export const SpanishMovementSubTypesEnum = {
    ORDERED: 'ORDENADO',
    RECEIVING: 'RECIBIDO',
    RETURN: 'DEVOLUCIÓN',
    DAMAGED: 'DAÑADO',
    DESTROYED: 'DESTRUIDO',
    LOST: 'PERDIDO',
    STOLEN: 'HURTADO',
    SEIZED: 'INCAUTADO',
    ADJUSTMENT: 'AJUSTE',
    RELOCATION: 'REUBICACIÓN',
    PENDING: 'PENDIENTE',
    CANCELLED: 'CANCELADA',
    CONFIRMED: 'CONFIRMADA',
}

export const MovementTypesDetail = [
    {
        type: MovementTypesEnum.INCOMING, subtype: { value: MovementSubTypesEnum.RECEIVING, label: "Nueva Recepción", show: true, }
    },
    {
        type: MovementTypesEnum.INCOMING, subtype: { value: MovementSubTypesEnum.RELOCATION, label: "Traslado", show: true, }
    },
    {
        type: MovementTypesEnum.INCOMING, subtype: { value: MovementSubTypesEnum.ADJUSTMENT, label: "Ajuste", show: true, }
    },
    {
        type: MovementTypesEnum.OUTGOING, subtype: { value: MovementSubTypesEnum.RELOCATION, label: "Traslado", show: true, }
    },
    {
        type: MovementTypesEnum.OUTGOING, subtype: { value: MovementSubTypesEnum.ADJUSTMENT, label: "Ajuste", show: true, }
    },
];

export class AddressStatusLabel {
    static FALLO = "Falló";
    static ASIGNADA = "Asignada";
    static GUIA = "Guía";
    static EN_TRANSITO_A_CIUDAD_DESTINO = "En tránsito a ciudad destino";
    static ASIGNADA_MENSAJERO = "Asignada a un mensajero";
    static INICIADO = "Iniciado";
    static ENTREGADO = "Entregado";
    static NO_ENTREGADO = "No entregado";
    static DEVUELTO = "Devolucion en transito";
    static DEVUELTO_REMITENTE = "Devuelto al remitente";
    static RECLAMAR_EN_SUCURSAL = "Para reclamar en sucursal";
}

export class ConfirmacionCierreEnum {
    static PAQUETE_COMPLETO = 'PAQUETE_COMPLETO';
    static PAQUETE_INCOMPLETO = 'PAQUETE_INCOMPLETO';
    static PAQUETE_NO_ENTREGADO  = 'PAQUETE_NO_ENTREGADO';
    static EFECTIVO_COMPLETO = 'EFECTIVO_COMPLETO';
    static EFECTIVO_INCOMPLETO = 'EFECTIVO_INCOMPLETO';
    static EFECTIVO_NO_ENTREGADO  = 'EFECTIVO_NO_ENTREGADO';
}

export const EstadoEnum = {
        PAQUETE_COMPLETO : 'Paquete completo',
        PAQUETE_INCOMPLETO : 'Paquete incompleto',
        PAQUETE_NO_ENTREGADO  : 'Paquete no Entregado',
        EFECTIVO_COMPLETO : 'Efectivo completo',
        EFECTIVO_INCOMPLETO : 'Efectivo incompleto',
        EFECTIVO_NO_ENTREGADO  : 'Efectivo no Entregado',
}

export class StatusEnum{
    static REPROG = "REPROG";
    static CREADO = "CREADO";
    static ENESPE = "ENESPE";
    static ASIGNA = "ASIGNA";
    static ALISTA = "ALISTA";
    static DESPAC = "DESPAC";
    static CENDIS = "CENDIS";
    static ENTRAN = "ENTRAN";
    static CENRET = "CENRET";
    static ENTREG = "ENTREG";
    static NOENTR = "NOENTR";
    static ANULAD = "ANULAD";
    static INVEST = "INVEST";
}

export class NoveltiesEnum {
   static N_EPDI = "N-EPDI";
   static N_RVU  = "N-RVU";
   static N_GURE = "N-GURE";
   static N_PPA  = "N-PPA";
   static N_SOOM = "N-SOOM";
   static N_NOIN = "N-NOIN";
   static N_PAOS = "N-PAOS";
   static N_PACK = "N-PACK";
   static N_PICK = "N-PICK";
   static N_AAUM = "N-AAUM";
   static N_AAUO = "N-AAUO";
   static N_RPO  = "N-RPO";
   static N_EHOP = "N-EHOP";
   static N_ETAC = "N-ETAC";
   static N_TACD = "N-TACD";
   static N_EHOD = "N-EHOD";
   static N_ENEF = "N-ENEF";
   static N_PCVT = "N-PCVT";
   static N_PCVN = "N-PCVN";
   static N_CLIM = "N-CLIM";
   static N_ZRPD = "N-ZRPD";
   static N_RES  = "N-RES";
   static N_EES  = "N-EES";
   static N_ORPU = "N-ORPU";
   static N_REAN = "N-REAN";
   static N_DEST = "N-DEST";
   static N_INCA = "N-INCA";
   static N_FALL = "N-FALL";
   static N_LOCE = "N-LOCE";
   static N_CNL  = "N-CNL";
   static N_CNRS = "N-CNRS";
   static N_CNRV = "N-CNRV";
   static N_CNRN = "N-CNRN";
   static N_DIIN = "N-DIIN";
   static N_DIER = "N-DIER";
   static N_DAR  = "N-DAR";
   static N_DET  = "N-DET";
   static N_PRRO = "N-PRRO";
   static N_PRPE = "N-PRPE";
   static N_PRDA = "N-PRDA";
   static N_NUDE = "N-NUDE";
   static N_CNR  = "N-CNR";
   static N_VNAA = "N-VNAA";
   static N_CNLD = "N-CNLD";
   static N_CNLI = "N-CNLI";
   static N_ENIN = "N-ENIN";
   static N_FDD = "N-FDD";
   static N_SECM = "N-SECM";
   static N_SICO = "N-SICO";
   static N_SPEC = "N-SPEC";
   static N_DSRT = "N-DSRT";
   static N_DCDM = "N-DCDM";
   static N_PLRH = "N-PLRH";
   static N_VRC = "N-VRC";
   static N_SMOV = "N-SMOV";
}

export const MimeTypesEnum = {
    CSV: ['text/csv', 'application/vnd.ms-excel']
}

export const HideDataForRolesEnum = {
    OPS_ATTENDANT: "OPS_ATTENDANT",
    BACKOFFICE_ATTENDANT: "BACKOFFICE_ATTENDANT",
}

export const InventoryReportTypesEnum = {
    MOVEMENTS: "MOVEMENTS",
    INCSOUTS: "INCSOUTS",
    STOCK: "STOCK",
}

export const alertTypesEnum = {
    EMAIL: "EMAIL",
    SMS: "SMS",
    WHATSAPP: "WHATSAPP",
}

export const alertStatusEnum = {
    READY: "READY",
    QUEUED: "QUEUED",
    NOTSENT: "NOTSENT",
    SENT: "SENT"
}

export const alertImportanceEnum = {
    1: "Baja",
    2: "Normal",
    3: "Alta",
}

export const hubTypeEnum = {
    REGIONAL: 'REGIONAL',
    LOCAL: 'LOCAL',
}

export const StatusDspToAssignCourier = [
    {
        statusCode: StatusEnum.ASIGNA, 
        name: 'Asignado' 
    },
    {
        statusCode: StatusEnum.DESPAC, 
        name: 'Despachado' 
    },
    {
        statusCode: StatusEnum.REPROG, 
        name: 'Reprogramado' 
    }
];

export class ManifestTypeEnum {
    static INCOMING = 1;
    static RETURN = 2;
    static RELOCATION = 3;
}

export const adjustmentMovementsTypes = [
    { value: "INCOMING", label: "Ingreso", code: 1 },
    { value: "OUTGOING", label: "Salida", code: 2 },
]

export const adjustmentMovementsSubtypes = [
    { value: "ADJUSTMENT_QTY", label: "Ajuste por diferencia de cantidades", code: 3 },
    { value: "ADJUSTMENT_CLI", label: "Ajuste por petición de cliente", code: 8 },
]